:root {
  --page-padding: 30px;
  --page-background: white;
  --component-spacing-large: 80px;
  --z-lowest: 0;
  --z-middle: 5;
  --z-highest: 10;
  --f-s: .718rem;
  --f-m: 1rem;
  --f-l: 1.392rem;
  --f-xl: 1.938rem;
  --f-xxl: 2.697rem;
  --f-xxxl: 3.755rem;
  --f-xxxxl: 5.226rem;
  --s-m: 24px;
  --s-l: 48px;
  --s-xl: 72px;
  --s-xxl: 144px;
  --s-xxxl: 216px;
  --c-border: #dbd9e4;
  --c-text: #292929;
  --c--highlight: #6cd59e;
  --c--highlight-darken: #32b371;
  --c-sketch: #433c32;
  --c--secondary: #ab244e;
}

@media (width >= 600px) {
  :root {
    --component-spacing-large: 100px;
  }
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

::selection {
  background: #00945940;
}

html {
  font-size: 16px;
}

body {
  background-color: var(--page-background);
  min-height: 100vh;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-optical-sizing: auto;
  flex-direction: column;
  margin: 0;
  font-family: Afacad, sans-serif;
  line-height: 1.5222;
  display: flex;
}

main {
  flex: 1;
}

strong {
  font-weight: 600;
}

a {
  color: inherit;
  font-family: inherit;
  text-decoration: none;
}

p {
  letter-spacing: .003em;
  word-break: break-word;
  color: var(--c-text);
}

ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

li {
  list-style-type: none;
}

ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

@media (width >= 600px) {
  ol {
    padding-left: 40px;
  }
}

img {
  filter: grayscale(.44);
  max-width: 100%;
  height: auto;
}

img:hover {
  filter: grayscale(0);
}

h1, h2, p {
  margin-top: 0;
  margin-bottom: 0;
}

.u-overflow-y-hidden {
  position: fixed;
  overflow-y: hidden;
}

.initalLoading header, .initalLoading main > div:first-child, .loading footer {
  display: none;
}

.leviating {
  animation: 2s cubic-bezier(.17, .67, .83, .67) infinite float;
  display: inline-block;
}

@keyframes float {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-3px);
  }

  100% {
    transform: translateX(0);
  }
}

img {
  transition: all 3ms cubic-bezier(.6, -.28, .735, .045);
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px)rotate(0);
  }

  10% {
    transform: translate(-1px, -2px)rotate(-1deg);
  }

  20% {
    transform: translate(-3px)rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px)rotate(0);
  }

  40% {
    transform: translate(1px, -1px)rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px)rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px)rotate(0);
  }

  70% {
    transform: translate(3px, 1px)rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px)rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px)rotate(0);
  }

  100% {
    transform: translate(1px, -2px)rotate(-1deg);
  }
}
/*# sourceMappingURL=index.5c16f3e2.css.map */
