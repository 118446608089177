:root {
    --page-padding: 30px;
    --page-background: white;

    --component-spacing-large: 80px;
    --z-lowest: 0;
    --z-middle: 5;
    --z-highest: 10;

    /* 1.414 augmented fourth type scale */
    --f-s: 0.718rem;
    --f-m: 1rem;
    --f-l: 1.392rem;

    /* 31px */
    --f-xl: 1.938rem;

    --f-xxl: 2.697rem;
    --f-xxxl: 3.755rem;
    --f-xxxxl: 5.226rem;

    --s-m: 24px;
    --s-l: 48px;
    --s-xl: 72px;
    --s-xxl: 144px;
    --s-xxxl: 216px;

    --c-border: #dbd9e4;

    --c-text: rgba(41, 41, 41, 1);
    --c--highlight: #6cd59e;
    --c--highlight-darken: #32b371;
    --c-sketch: #433c32;

    --c--secondary: #ab244e;
}

@media (min-width: 600px) {
    :root {
        --component-spacing-large: 100px;
    }
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

::selection {
    background: #00945940;
}

html {
    font-size: 16px;
}

body {
    margin: 0;
    background-color: var(--page-background);
    display: flex;
    min-height: 100vh;
    line-height: 1.5222;
    flex-direction: column;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: "Afacad", sans-serif;
    font-optical-sizing: auto;
}

main {
    flex: 1;
}

strong {
    font-weight: 600;
}

a {
    color: inherit;
    text-decoration: none;
    font-family: inherit;
}


p {
    letter-spacing: 0.003em;
    word-break: break-word;
    color: var(--c-text);
}


ul {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}


li {
    list-style-type: none;
}

ol {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

@media (min-width: 600px) {
    ol {
        padding-left: 40px;
    }
}

img {
    max-width: 100%;
    height: auto;
    filter: grayscale(0.44);
}

img:hover {
    filter: grayscale(0);
}

h1,
h2,
p {
    margin-top: 0;
    margin-bottom: 0;
}


.u-overflow-y-hidden {
    overflow-y: hidden;
    position: fixed;
}


.initalLoading header,
.initalLoading main>div:first-child {
    display: none;
}

.loading footer {
    display: none;
}

/* floating arrow on home */
.leviating {
    animation: float 2s cubic-bezier(0.17, 0.67, 0.83, 0.67) infinite;
    display: inline-block;
}

@keyframes float {
    0% {
        transform: translateX(0px);
    }

    50% {
        transform: translateX(-3px);
    }

    100% {
        transform: translateX(0px);
    }
}

img {
    transition: cubic-bezier(0.6, -0.28, 0.735, 0.045) 3ms all;
}

/*
.shake:hover {
    animation: shake 0.7s;
    animation-iteration-count: infinite;
}
*/
@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}